import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { breakpoints, BuyNowLinkCSS } from 'src/utils/styles/global-styles'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'

const PressPage = () => {
  const { press } = useStaticQuery(graphql`
    query CombinedQuery {
      press: allContentfulPress {
        edges {
          node {
            order
            description
            link
            name
            title
            image {
              references {
                id
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Skylight | Stay Informed: Explore the Latest Skylight News in Our Press Room"
        description="Skylight Frame Press Room. Discover up-to-date Skylight news, features updates and more"
        path="/"
      />
      <Container>
        <TextContainer>
          <Title>Press Room</Title>
          <Subheading>
            For all media inquiries, please contact us at{' '}
            <EmailLink href="mailto:press@skylightframe.com">press@skylightframe.com</EmailLink>
          </Subheading>
          <PressKitLink
            href="https://bigfish.egnyte.com/fl/ibP77Ifo2N#folder-link/Press%20Page%20Media%20Kit"
            rel="noopener noreferrer"
            target="_blank"
          >
            View Press Kit
          </PressKitLink>
        </TextContainer>
        <Grid>
          {press.edges
            .sort((a, b) => {
              if (a.node.order < b.node.order) return -1
              if (a.node.order > b.node.order) return 1
              return 0
            })
            .map(({ node: { description, link, name, title, image } }, index) => (
              <GridItem key={index}>
                <LogoContainer>
                  <GatsbyImage image={image.references[0].gatsbyImageData} alt={`${name}Logo`} />
                </LogoContainer>
                <PressTitle>{title}</PressTitle>
                <PressDescription>{description}</PressDescription>
                <Link href={link} target="_blank" rel="noopener noreferrer" flex>
                  Read Article <AiOutlineArrowRight />
                </Link>
              </GridItem>
            ))}
        </Grid>
      </Container>
    </>
  )
}

export default PressPage

export const Title = styled.h1`
  font-size: 42px;
  text-align: center;
  font-family: 'P22MackinacProMedium';
  margin: 20px 0px 0px;
`

export const Subheading = styled.p`
  font-size: 18px;
  text-align: center;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 1rem;
  }
`

export const PressTitle = styled.h4`
  font-family: 'P22MackinacProMedium';
  font-size: 1.75rem;
  margin: 10px 0px 0px;
  height: 110px;
  overflow: hidden;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.2rem;
    height: auto;
  }
`

export const PressDescription = styled.p`
  font-family: 'FilsonProBook';
  height: 110px;
  overflow: hidden;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 1rem;
    height: auto;
  }
`

export const Container = styled.div`
  width: 90%;
  margin: auto;
  padding: 50px 0px 80px;
  @media (max-width: ${breakpoints.s}px) {
    height: auto;
    padding: 40px 0px;
  }
`
export const LogoContainer = styled.div`
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 130px;
    width: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    height: auto;
    margin-bottom: 20px;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  padding: 40px 0px;
  max-width: 1200px;
  margin: 40px auto 0px;
  @media (max-width: ${breakpoints.xl}px) {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.m}px) {
    grid-template-columns: 1fr;
  }
`
export const GridItem = styled.div`
  position: relative;
  padding: 20px 20px 40px 20px;
  background-color: rgba(255, 254, 249, 0.2);
  border: 0px solid transparent;
  border-radius: 16px;
  box-shadow: rgba(34, 34, 34, 0.18) 0px 6px 16px, rgba(34, 34, 34, 0.1) 0px 2px 2px;
`

const linkStyle = css`
  outline: none;
  border: 0;
  color: ${(props) => props.theme.blueDark};
  text-decoration: none;
  display: ${(props) => (props.flex ? 'flex' : 'inline-block')};
  align-items: center;
  font-size: 18px;
  a:hover,
  a:active,
  a:focus {
    outline: none;
    border: 0;
  }
  svg {
    margin-left: 4px;
    margin-bottom: 2px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 1rem;
  }
`
const EmailLink = styled.a`
  ${linkStyle}
`
const Link = styled.a`
  ${linkStyle}
  position: absolute;
  bottom: 15px;
`
export const PressKitLink = styled.a`
  ${BuyNowLinkCSS}
  display: inline-block;
`
export const TextContainer = styled.div`
  text-align: center;
`
